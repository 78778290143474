import { fieldMappings } from 'components/transaction/components/panels/transaction/announcements/announcementsConfig';
import { deepMerge } from 'components/transaction/components/panels/transaction/announcements/appointmentsTable/appointmentHelper';
import { axiosAiq } from 'config';
import { omit } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { notificationHandler } from 'utils/utils';
import { selectFormById } from '../formControlSelectors';
import { formControlActions } from '../formControlSlice';
import { AUTOMATION_SOURCE } from 'utils/constants';

class CopyToFormSagas {
    init = () => {
        return [
            call(this.fromBisonAnnouncementTable.bind(this)),
            call(this.fromLocationTransactionsTable.bind(this)),
            call(this.fromAppointmentsTable.bind(this)),
        ];
    };

    *fromBisonAnnouncementTable() {
        yield takeEvery(formControlActions.copyBisonAnnouncement.type, this.copyBisonAnnouncement.bind(this));
    }

    *fromLocationTransactionsTable() {
        yield takeEvery(formControlActions.copyLocationTransaction.type, this.copyLocationTransaction.bind(this));
    }

    *fromAppointmentsTable() {
        yield takeEvery(formControlActions.copyAppointmentData.type, this.copyAppointmentData.bind(this));
    }

    *copyBisonAnnouncement({ payload: { transactionId, tableName: table, values, key, copy } }) {
        const tableName = copy ? table : '';
        const internalIdAnnouncementUsed = copy ? key : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, transactionId, values)));
        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));
        yield put(formControlActions.setAnnouncementIdUsed({ formId: transactionId, internalIdAnnouncementUsed }));
        copy && (yield put(formControlActions.setFieldValue({ formId: transactionId, fieldName: 'sendToBison', value: true })));

        yield put(
            formControlActions.validateTransactionWithInventoryData({
                formId: transactionId,
            })
        );
        function getAutomationValuesActionObj(select, transactionId, values) {
            if (select)
                return {
                    transactionId,
                    automationObjTemplate: { source: AUTOMATION_SOURCE.BISON_ANNOUNCEMENT },
                    customSource: { values, fieldMappings },
                };
            return { transactionId, setAlisaAutomation: true };
        }
    }

    *copyAppointmentData({ payload: { transactionId, values, copy, appointmentId, reopenTransaction } }) {
        const existingForm = yield select(selectFormById(transactionId));
        if (copy) {
            try {
                const existingForm = yield select(selectFormById(transactionId));
                const initialTransactionData = existingForm?.transactionData || {};

                // Reset the transaction data if the appointment is already set
                const currTransactionData =
                    initialTransactionData?.appointment?.id !== undefined
                        ? (yield axiosAiq.get(`/transactions/trucks/${transactionId}`)).data
                        : initialTransactionData;

                const transactionData = deepMerge(currTransactionData, {
                    ...omit(values, ['number', 'plannedTime', 'status', 'waitingReason']),
                    id: transactionId,
                    appointment: { id: appointmentId },
                    gate: currTransactionData?.gate,
                });

                yield put(formControlActions.setTransactionData({ transactionData }));
            } catch (err) {
                yield notificationHandler({ err, title: `ERROR COPYING DATA TO FORM` });
                return;
            }
        }
        if (!copy) {
            // get appointment id from reopenTransaction store
            const appointmentId = reopenTransaction?.appointment?.id ?? null;
            const transactionData = {
                ...existingForm?.transactionData,
                appointment: appointmentId != null ? { id: appointmentId } : null,
            };
            yield put(formControlActions.setTransactionData({ transactionData }));
        }

        const tableName = copy ? 'appointments' : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, transactionId)));

        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));

        yield put(
            formControlActions.validateTransactionWithInventoryData({
                formId: transactionId,
            })
        );

        function getAutomationValuesActionObj(select, transactionId) {
            const source = AUTOMATION_SOURCE.APPOINTMENT;
            if (select) return { transactionId, automationObjTemplate: { source } };
            return { transactionId, setAlisaAutomation: true };
        }
    }

    *copyLocationTransaction({ payload: { transactionId, announcementsMode, copy, tableName: table, key } }) {
        if (copy) {
            try {
                const urlPath = `/transactions/trucks${announcementsMode ? '/announcements' : ''}/from/${key}/to/${transactionId}`;
                const { data: transactionData } = yield axiosAiq.get(urlPath);
                yield put(formControlActions.setTransactionData({ transactionData }));
            } catch (err) {
                yield notificationHandler({ err, title: `ERROR COPYING DATA TO FORM` });
                return;
            }
        }

        const manualAnnouncementIdUsed = copy && announcementsMode ? key : '';
        const tableName = copy ? table : '';

        yield put(formControlActions.setAutomationValues(getAutomationValuesActionObj(copy, announcementsMode, transactionId)));

        yield put(formControlActions.setAnnouncementIdUsed({ formId: transactionId, manualAnnouncementIdUsed }));

        yield put(formControlActions.setActiveSelectionTableName({ formId: transactionId, tableName }));

        yield put(
            formControlActions.validateTransactionWithInventoryData({
                formId: transactionId,
            })
        );

        function getAutomationValuesActionObj(select, announcementsMode, transactionId) {
            const source = announcementsMode ? AUTOMATION_SOURCE.MANUAL_ANNOUNCEMENT : AUTOMATION_SOURCE.HISTORICAL_TRANSACTION;
            if (select) return { transactionId, automationObjTemplate: { source } };
            return { transactionId, setAlisaAutomation: true };
        }
    }
}

export default new CopyToFormSagas();
