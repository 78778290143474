import { Button } from '@mui/material';
import { useState } from 'react';
import styles from './ProcessingSection.module.css';
import LinearLoader from 'components/UI/Loaders/LinearLoader';
import { PROFILE } from 'config';
function ProcessingSection({
    processedSnapshots,
    sendingSnapshots,
    onSendSnapshots,
    hasActiveCamera,
    processedSnapshotsLoaded,
    isTransactionProcessed,
    selectedSnapshots,
    getCheckdValue,
}) {
    const hasProcessedSnapshots = !!processedSnapshots.length;
    const [checkedState, setCheckedState] = useState(false);
    const containerClasses = `${styles['processing-container']} ${
        styles[`processing-container--${processedSnapshotsLoaded ? 'expanded' : 'shrinked'}`]
    }`;

    const changeHandler = (e) => {
        const checked = e.currentTarget.checked;
        setCheckedState(checked);
        getCheckdValue(checked);
    };

    return (
        <div className={styles.wrapper} id="processing-container">
            {hasActiveCamera && (
                <div className={containerClasses}>
                    {!hasProcessedSnapshots && !sendingSnapshots && !isTransactionProcessed && (
                        <div className={styles['processing-actions']}>
                            <Button
                                variant="contained"
                                color="success"
                                size="large"
                                sx={{ visibility: sendingSnapshots ? 'hidden' : 'visible' }}
                                disabled={!selectedSnapshots?.id}
                                onClick={onSendSnapshots}
                            >
                                Process
                            </Button>
                            {PROFILE !== 'production' && (
                                <div className={styles['processing-checkbox']}>
                                    <input checked={checkedState} onChange={changeHandler} type="checkbox" id="production-enviroment" />
                                    <label htmlFor="production-enviroment">useMock</label>
                                </div>
                            )}
                        </div>
                    )}
                    {sendingSnapshots && <LinearLoader containerStyles={{ width: '80%', left: '10%' }} />}
                </div>
            )}
        </div>
    );
}

export default ProcessingSection;
