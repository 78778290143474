import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from './rootSaga';

//reducers
import { desksReducer } from './desksSlice/desksSlice';
import { transactionsReducer } from './transactionsSlice/transactionsSlice';
import { snackbarsReducer } from './snackbarsSlice/snackbarsSlice';
import { tableControlReducer } from './tableControlSlice/tableControlSlice';
import { formControlReducer } from './formControlSlice/formControlSlice';
import { authReducer } from './authSlice/authSlice';
import { generalReducer } from './generalSlice/generalSlice';
import { featureFlagReducer } from './featureFlag/featureFlagSlice';
import { dynamicFormReducer } from 'features/dynamicForm/store/dynamicFormSlice';
import { relayActivityReducer } from 'features/relayActivity/store/relayActivitySlice';

const devTools = import.meta.env.VITE_ENV !== 'production' ? { trace: true, traceLimit: 25 } : false;

export let store = {};
let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const setupStore = (preloadedState) => {
    store = configureStore({
        reducer: {
            forms: formControlReducer,
            desks: desksReducer,
            transactions: transactionsReducer,
            snackbars: snackbarsReducer,
            tables: tableControlReducer,
            auth: authReducer,
            general: generalReducer,
            featureFlag: featureFlagReducer,
            dynamicForm: dynamicFormReducer,
            relayActivity: relayActivityReducer,
        },
        devTools,
        ...(preloadedState && { preloadedState }),
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: ['transactions/setSharedWorker', 'desks/fetchFailed'],
                    ignoredPaths: ['transactions.sharedWorker'],
                },
            }).concat(middleware),
    });

    sagaMiddleware.run(rootSaga);
    return store;
};
