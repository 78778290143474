import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSidePanelOpen: false,
};

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setIsSidePanelOpen(state, { payload: isOpen }) {
            state.isSidePanelOpen = isOpen;
        },
    },
});

export const generalActions = generalSlice.actions;
export const generalReducer = generalSlice.reducer;
