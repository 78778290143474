import { formControlActions } from 'store/formControlSlice/formControlSlice';
import { store } from 'store/store';
import { cleanInputValue, formatInputValue, validateInputValue } from './fieldValdiation/utils';
let fieldChanged = false;

export const changeFieldValue = function (formId, fieldName, value = null, error) {
    store.dispatch(
        formControlActions.setFieldValue({
            formId,
            fieldName,
            value,
        })
    );

    if (error) {
        store.dispatch(
            formControlActions.setFieldError({
                formId,
                fieldName,
                error: false,
            })
        );
    }
};
export const changeHandler = function (e, newValue) {
    const { lookup, formId, fieldName, error, type, referenceField, autofillFormFields } = this;
    fieldChanged = true;

    let value =
        newValue !== undefined ? (lookup.displayExpr ? (lookup.valueExpr ? newValue?.[lookup.valueExpr] : newValue) : newValue) : e.target.value;

    if (type === 'number' && value !== '') value = +value;

    changeFieldValue(formId, fieldName, value, error);
    autofillFormFields?.(newValue);

    if (referenceField) changeFieldValue(formId, referenceField, newValue[lookup.referenceExpr]);
};

export const inputValueChangeHandler = function (_, newValue) {
    const { lookup, formId, fieldName, error, type, referenceField, referenceValue } = this;
    fieldChanged = true;
    if (!lookup.displayExpr || referenceField) {
        const value = type === 'number' ? +newValue : newValue;
        changeFieldValue(formId, fieldName, value, error);
        if (referenceValue && referenceField !== fieldName) changeFieldValue(formId, referenceField, null);
    }
};

export const blurHandler = function blurHandler(e) {
    const { required, touched, error, formId, fieldName, validationField, validationRules, format } = this;
    let value = e.target.value;
    if (fieldName.includes('[]')) {
        value = value.split(',').map((value) => formatInputValue(cleanInputValue(value), format));
    } else {
        value = formatInputValue(cleanInputValue(value), format);
    }
    value !== e.target.value && changeFieldValue(formId, fieldName, value, error);

    const errorMessage = validateInputValue(value, validationRules);
    const hasError = value === '' ? required && !error : errorMessage;

    if (hasError) {
        store.dispatch(
            formControlActions.setFieldError({
                formId,
                fieldName,
                error: hasError,
            })
        );
    }

    if (!touched) {
        store.dispatch(
            formControlActions.setFieldTouched({
                formId,
                fieldName,
            })
        );
    }
    store.dispatch(
        formControlActions.validateTransactionWithInventoryData({
            formId,
        })
    );

    if (validationField && fieldChanged && !e.target.inputBlurOnQuickSubmit) {
        store.dispatch(
            formControlActions.validateTransactionFormField({
                formId,
                fieldName,
            })
        );
    }
    fieldChanged = false;
    if (e.target.inputBlurOnSubmit) e.target.inputBlurOnQuickSubmit = false;
};

export const getFieldConfig = function ({
    formId,
    fieldName,
    label,
    required,
    type,
    helperText = 'This field is required',
    value,
    error,
    touched,
    initialValue,
    disabled,
    inputProps,
}) {
    const customLabel = required ? label + '*' : label;
    const customHelperText = typeof error === 'string' ? error : helperText;
    return {
        formId,
        fieldName,
        label: customLabel,
        required,
        type,
        changeHandler,
        blurHandler,
        inputValueChangeHandler,
        helperText: customHelperText,
        value,
        error,
        touched,
        initialValue,
        disabled,
        inputProps,
        changeFieldValue,
    };
};

export const displayFieldCustomError = function (formId, fieldName, errorText) {
    store.dispatch(
        formControlActions.setFieldError({
            formId,
            fieldName,
            error: errorText,
        })
    );

    store.dispatch(
        formControlActions.setFieldTouched({
            formId,
            fieldName,
        })
    );
};

export function populateEmptyTransactionFormFields(formObj, defaultValue = '/') {
    const {
        form: { values, errors, automation },
        formConfig: { fields },
        transactionData: { id: formId },
    } = formObj;

    const fieldsToPopulate = getEmptyFormFields(values);

    if (!fieldsToPopulate.length) return formObj;

    fields.forEach((field) => {
        const { dataField: fieldName, validationRules, format } = field;
        const isRequired = !!(validationRules && validationRules.find(({ type }) => type === 'required'));
        if (isRequired && fieldsToPopulate.includes(fieldName)) {
            const value = automation?.[fieldName]?.sourceValue ?? defaultValue;
            const formatedValue = formatInputValue(cleanInputValue(value), format);
            populateTransactionFormField(formId, errors, field, formatedValue);
            const error = validateInputValue(formatedValue, validationRules);
            if (error) {
                store.dispatch(
                    formControlActions.setFieldError({
                        formId,
                        fieldName,
                        error,
                    })
                );
            }
        }
    });
    const newFormObj = store.getState().forms.forms[formId];
    return newFormObj;
}

const getEmptyFormFields = (valuesObj) =>
    Object.keys(valuesObj).reduce((emptyFields, fieldName) => {
        const currentValue = valuesObj[fieldName];
        if (!currentValue && currentValue !== false && currentValue !== 0) emptyFields.push(fieldName);
        return emptyFields;
    }, []);

const populateTransactionFormField = (formId, errors, field, newValue) => {
    const { dataField, lookup, referenceField, dataType } = field;
    const hasError = errors?.[dataField];
    //standard inputs or dropdowns with free solo
    if (!lookup || (lookup && (!lookup?.displayExpr || referenceField))) {
        changeFieldValue(formId, dataField, newValue, hasError);
        return;
    }

    //dropdown with strict options
    if (lookup.dataSource.length && lookup?.displayExpr) {
        const valueObjToSet = lookup.dataSource.find((value) => {
            return value[lookup.displayExpr] === newValue;
        });

        if (valueObjToSet) {
            changeHandler.call({ lookup, formId, fieldName: dataField, error: hasError, referenceField, type: dataType }, undefined, valueObjToSet);
        }
    }
};
