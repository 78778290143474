import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allDesks: [],
    filteredDesks: [],
    allDeskLocations: [],
    savedDesk: [],
    currentDesk: {},
    isActiveDeskConfirmed: true,
    fetchingDesksError: false,
    fetchingDesksInProgress: undefined,
    fetchingLocationsInProgress: undefined,
    fetchingSavedDeskInProgress: undefined,
    fetchingSavedDesksError: false,
    isDeskLoading: false,
    maxDeskTransactions: 0,
    expectedFinishedTransactionMillis: 0,
    deskWarnings: { warnings: [] },
    pauseRequestSent: false,
    tableData: [],
    satorationPayload: [],
    disableSaveButton: true,
    locationItems: {},
    savingDeskFinished: false,
    notFoundFilteredDeskMessage: '',
    isFetchingSaturationInfo: true,
};

const desksSlice = createSlice({
    name: 'desks',
    initialState,
    reducers: {
        fetchDesksStart(state) {
            state.fetchingDesksInProgress = true;
            state.fetchingDesksError = false;
        },

        fetchLocationsStart(state) {
            state.fetchingLocationsInProgress = true;
            state.fetchingDesksError = false;
        },

        fetchSavedDeskStart(state) {
            state.fetchingSavedDeskInProgress = true;
            state.fetchingSavedDesksError = false;
        },

        fetchDesksSuccess(state, { payload }) {
            state.fetchingDesksInProgress = false;
            state.isFetchingSaturationInfo = false;
            state.allDesks = payload;
            state.filteredDesks = payload;
        },

        fetchLocationsSuccess(state, { payload }) {
            state.fetchingLocationsInProgress = false;
            state.allDeskLocations = payload;
        },

        fetchSavedDesksSuccess(state, { payload }) {
            state.fetchingSavedDeskInProgress = false;
            state.savedDesk = payload;
        },

        fetchSavedDesksFailed(state, { payload }) {
            state.fetchingSavedDeskInProgress = false;
            state.fetchingSavedDesksError = payload;
        },

        fetchDesksFailed(state, { payload }) {
            state.fetchingDesksInProgress = false;
            state.fetchingDesksError = payload;
        },

        setCurrentDeskInactive(state) {
            state.allDesks.forEach((desk) => {
                if (desk.id === state.currentDesk.id) desk.status = 'INACTIVE';
            });
        },

        setCurrentDesk(state, { payload: desk }) {
            state.currentDesk = desk;
            if (desk) state.pauseRequestSent = false;
            if (!Object.keys(desk).length) state.isActiveDeskConfirmed = true;
        },

        selectDesk() {},

        saveDeskInfo(state) {
            state.disableSaveButton = true;
        },

        setMaxDeskTransactions(state, { payload }) {
            state.maxDeskTransactions = payload;
        },

        setExpectedFinishedTransactionMillis(state, { payload }) {
            state.expectedFinishedTransactionMillis = payload;
        },

        replaceDesk(state, { payload: deskToReplace }) {
            state.allDesks = state.allDesks.map((desk) =>
                desk.id === deskToReplace.id ? { ...deskToReplace, ...(desk.locations && { locations: desk.locations }) } : desk
            );
        },
        setIsActiveDeskConfirmed(state, { payload: isActiveDeskConfirmed }) {
            state.isActiveDeskConfirmed = isActiveDeskConfirmed;
        },

        setDeskWarnings(state, { payload: { deskWarnings, lastChecked } }) {
            state.deskWarnings.warnings = deskWarnings.reduce((warnings, { args, code }) => {
                if (code === 'INACTIVE_DESK_WITH_TRANSACTIONS') {
                    warnings.push(`${args[0]} (${args[1]})`);
                }
                state.deskWarnings.lastChecked = lastChecked;
                return warnings;
            }, []);
        },

        setIsDeskLoading(state, { payload: isLoading }) {
            state.isDeskLoading = isLoading;
        },
        setPauseRequestSent(state, { payload: pauseRequestSent }) {
            state.pauseRequestSent = pauseRequestSent;
        },
        checkDeskWarnings() {},

        setTableData(state, { payload }) {
            state.tableData = payload;
        },
        updateTableData(state, { payload }) {
            state.tableData = state.tableData.map((row) => {
                if (row.id === payload.id) {
                    return {
                        ...row,
                        rowValues: payload.rowValues,
                        expandedRowValues: payload.expandedRowValues,
                        specificGatesSaved: payload.specificGatesSaved,
                    };
                }
                return row;
            });
            state.disableSaveButton = false;
        },
        saveSaturationPayload(state, { payload }) {
            state.saturationPayload = payload;
        },
        setSavingDeskFinished(state, { payload }) {
            state.savingDeskFinished = payload;
        },

        findDesk(state, { payload }) {
            if (!payload || payload.trim() === '') {
                state.allDesks = [...state.filteredDesks];
                state.notFoundFilteredDeskMessage = '';
            } else {
                const filteredDesks = state.filteredDesks.filter((desk) => desk.name.toLowerCase().includes(payload.toLowerCase()));
                state.allDesks = filteredDesks.length > 0 ? filteredDesks : state.allDesks;
                state.notFoundFilteredDeskMessage = filteredDesks.length === 0 ? 'No desk found. Try another keyword.' : '';
            }
        },
    },
});

export const desksReducer = desksSlice.reducer;
export const desksActions = desksSlice.actions;
