import { Divider, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { INVENTORY_STATUS, INVENTORY_STATUS_LABEL } from 'utils/constants';
import styles from './Option.module.css';

const InventoryOption = ({ option, optionRef, fieldName }) => {
    if (!option) return null;
    const { inventoryMappings } = option;
    const firstInventoryMapping = inventoryMappings?.[0];
    const inventoryName = firstInventoryMapping?.inventoryItemType?.name;
    const inventoryStatus = firstInventoryMapping?.inventoryStatus;

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography fontWeight={600} ref={optionRef}>
                    {option[fieldName]}
                </Typography>
                <Typography fontSize="0.8rem">{inventoryName}</Typography>
            </Stack>
            <Divider />
            <Stack spacing={0.5}>
                <Typography fontSize="0.8rem" fontWeight={400}>
                    <span className={styles.title}>Status:</span>
                    <span className={styles[getStyle(inventoryStatus)]}>&#9679;</span>
                    {INVENTORY_STATUS_LABEL[inventoryStatus]}
                </Typography>
            </Stack>
        </>
    );
};

InventoryOption.propTypes = {
    option: PropTypes.shape({
        inventoryMappings: PropTypes.array,
        data: PropTypes.object,
    }),
    optionRef: PropTypes.object,
    fieldName: PropTypes.string,
};

const getStyle = (status) => (status === INVENTORY_STATUS.GOOD_TO_GO ? 'good-to-go' : 'terminated');

export default InventoryOption;
