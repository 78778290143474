import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { relayActivityActions } from './relayActivitySlice';
import { notificationHandlerSync } from 'utils/utils';
import { fromIsoString } from 'utils/dateTimeUtils';
import { formatDate } from 'utils/dateTimeUtils';
import * as relayActivitiesSelector from './relayActivitySelectors';
import { axiosAiq } from 'config';

export function* fetchAllRelayActivity(payload) {
    try {
        const rowsPerPage = yield select(relayActivitiesSelector.rowsPerPage);
        let isLocation = payload.payload?.location ? `&locationId=${payload.payload.location}` : '';
        let isDate = payload.payload?.date ? `&startDateTime=${payload.payload.date}&endDateTime=${payload.payload.date}` : '';
        let pageNumber = payload.payload?.pageNumber ? `&page=${payload.payload.pageNumber}` : '';
        const response = yield axiosAiq(`/relay/usage-log?size=${rowsPerPage}${pageNumber}${isLocation}${isDate}`);
        const { data } = response;

        data.content.map((el) => {
            el.created = formatDate(fromIsoString(el.created), 'MM/dd/yyyy hh:mm a');
            el.gateName = `${el.gate.name} (${el.gate.direction})`;
            return el;
        });

        yield put(relayActivityActions.getRelayActivities(data));
    } catch (err) {
        notificationHandlerSync({ err, title: 'Something went wrong!' });
    }
}

export function* fetchAllLocations() {
    try {
        const response = yield axiosAiq('/locations');
        const { data } = response;
        yield put(relayActivityActions.setAllLocations(data));
    } catch (err) {
        notificationHandlerSync({ err, title: 'Something went wrong!' });
    }
}

export function* onfetchAlllocations() {
    yield takeEvery(relayActivityActions.fetchAllLocations.type, fetchAllLocations);
}

export function* onfetchAllRelayActivity() {
    yield takeEvery(relayActivityActions.getRelayActivitiesStart.type, fetchAllRelayActivity);
}
export const relayActivitySaga = function* () {
    yield all([call(onfetchAllRelayActivity), call(onfetchAlllocations)]);
};
