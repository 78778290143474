import CameraSection from './cameraSection/CameraSection';

function CameraSections({
    onTakeSnapshot,
    onRemoveSnapshot,
    snapshots,
    warnings,
    sendingSnapshots,
    cameras,
    onClickHandler,
    emptySelectedSnapshot,
    selectedSnapshots,
}) {
    const cameraSections = cameras.map((stream) => {
        const { props } = stream;
        const {
            videoOutputId,
            camConfig: { camId, type },
        } = props;

        return (
            <CameraSection
                key={videoOutputId}
                cameraId={camId}
                cameraType={type}
                stream={stream}
                videoOutputId={videoOutputId}
                onTakeSnapshot={onTakeSnapshot}
                onRemoveSnapshot={onRemoveSnapshot}
                snapshots={snapshots[camId]}
                warning={warnings[camId]}
                sendingSnapshots={sendingSnapshots}
                onClickHandler={onClickHandler}
                emptySelectedSnapshot={emptySelectedSnapshot}
                selectedSnapshots={selectedSnapshots}
            />
        );
    });
    return <>{cameraSections}</>;
}

export default CameraSections;
