import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    isUserDataRequested: false,
    streamManagerToken: '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, { payload: user }) {
            state.user = user;
        },
        setStreamManagerToken(state, { payload: streamManagerToken }) {
            state.streamManagerToken = streamManagerToken;
        },
        setIsUserDataRequested(state, { payload }) {
            state.isUserDataRequested = payload;
        },
        //placeholders
        logoutStart() {},
        getUserData() {},
    },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
