import axios from 'axios';

export const PROFILE = import.meta.env.VITE_ENV;

const service_port = import.meta.env.VITE_SERVICE_PORT;
const host_name = import.meta.env.VITE_HOSTNAME;

const protocol_http = import.meta.env.VITE_PROTOCOL;
const protocol_ws = import.meta.env.VITE_PROTOCOL_WS;

const isLocalHost = host_name === 'localhost';

const domain = process.env.NODE_ENV === 'test' || !isLocalHost ? host_name : 'localhost:' + service_port;

const main_address_http = protocol_http + domain;
const main_address_wss = protocol_ws + domain;

const socket = main_address_wss + '/socket';
const websocket = socket + '/websocket';
const topic = websocket + '/topic';

function getServiceUrlSuffix() {
    switch (PROFILE) {
        case 'production':
            return '';
        case 'staging':
            return '-staging';
        case 'develop':
            return '-develop';
        case 'development':
        default:
            return '-development';
    }
}

const service_url_suffix = getServiceUrlSuffix();

const SSOAdresses = {
    LOCAL: {
        REDIRECT_TO: 'http://localhost:3001',
        MAIN: 'http://localhost:8080',
    },
    DEPLOYED: `https://sso${service_url_suffix}.birdseyesecurity.com`,
};

export const GENERAL = {
    PATHS: {
        HOOKS: '/hooks',
        COMPONENTS: '/components',
        PAGES: '/pages',
    },

    ADDRESSES: {
        DOMAIN: domain,
        DOMAIN_MAIN: import.meta.env.VITE_HOSTNAME,

        HTTP: {
            MAIN: main_address_http,
            HEALTH: main_address_http + '/health',
        },

        WSS: {
            WEBSOCKET: websocket,
            DESKS: topic + '/desks',
        },
        SSO: protocol_http === 'http://' ? SSOAdresses.LOCAL.REDIRECT_TO : SSOAdresses.DEPLOYED,
        ALERT: `wss://alert${service_url_suffix}.birdseyesecurity.com/socket`,
    },

    PREFIXES: {
        STORAGE: 'bfs_aiq_',
    },
};

export const tvConfig = {
    CAM_LARGE_VIEWS_LIMIT: 4,
};

export const snackBarGeneralConfiguration = {
    maxSnack: 7, //maxNumberOfNotificationsOnScreen
    autoHideDuration: 6000, //number of ms before hiding snackbar general config, can be overwritten for each snackbar when initialising it
};

export const webSocketGeneralConfig = {
    timesToTryReconnect: 30,
    reconnectDelay: 1000,
};

export const stompClientConfig = {
    brokerURL: GENERAL.ADDRESSES.WSS.WEBSOCKET,
    connectHeaders: {},
    discardWebsocketOnCommFailure: true,
    // debug: function (str) {
    //     if (str !== 'Received data' && str !== '<<< PONG' && str !== '>>> PING') console.log(str);
    // },

    reconnectDelay: webSocketGeneralConfig.reconnectDelay, //in case ws connection fails or disconected, recconection will be tried [reconnectDelay]ms. Seems to be infinite loop of retries
    connectionTimeout: 30000, //it may occur that connection is succesful, but we never get back response on 'CONNECT' frame sent, in that case after [connectionTimeout]ms ws connection is closed and  new reconnect will be scheduled in [reconnectDelay]ms. Otherwise (without this property) initial ws connection remains open and waits forever.
    heartbeatIncoming: 60000,
    heartbeatOutgoing: 60000,
};

export const WS_CONNECTION_STATE = Object.freeze({ CONNECTED: 'CONNECTED', CONNECTING: 'CONNECTING', DISCONNECTED: 'DISCONNECTED' });

export const locationTransactionsTableRowsPerPage = 21;
export const allLocationsTransactionsTableRowsPerPage = 750;

export const axiosAiq = axios.create({
    baseURL: GENERAL.ADDRESSES.HTTP.MAIN,
    withCredentials: true,
});

export const axiosSSO = axios.create({
    baseURL: protocol_http === 'http://' ? SSOAdresses.LOCAL.MAIN : SSOAdresses.DEPLOYED,
    withCredentials: true,
});

export const audioCameraTypes = ['ID', 'TRUCK_ID'];

//transaction page //TO DO  move it to separate file close to TransactionPage.jsx
export const TRANSACTION_PAGE_TABS = {
    DIVISION_TAB: { TITLE: 'Division', ID: 'division' },
    VIDEO_WALL_TAB: { TITLE: 'Wall', ID: 'wall', DUMMY_TRANSACTION_ID: 'videoWall' },
    REOPENED_TRANSACTION_TAB: { TITLE: 'Reopen', ID: 'reopenedTransaction' },
};

export const checkDeskWarningsInterval = 20000;

export const transactionPageDefaultMode = {
    videoWallEnabled: false,
    receivingTransactionsEnabled: true,
};

export const sessionStorageKeys = {
    videoWallEnabled: 'videoWallEnabled',
    receivingTransactionsEnabled: 'receivingTransactionsEnabled',
};

export const clientPersistentSessionStorageKeys = [sessionStorageKeys.videoWallEnabled, sessionStorageKeys.receivingTransactionsEnabled];

//

export const scrollTo = {
    topOfPage: {
        top: 0,
        left: 0,
        behavior: 'smooth',
    },
    transactionView: {
        top: 27,
        left: 0,
        behavior: 'smooth',
    },
};
