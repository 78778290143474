import { LOCATION_ITEMS_KEY, DESK_ITEMS_KEY, DESK_SAVED_LOCATION_ITEMS_KEY } from 'utils/constants';

export const getLocationItems = () => {
    const storedItems = localStorage.getItem(LOCATION_ITEMS_KEY);
    return storedItems ? JSON.parse(storedItems) : {};
};

export const setLocationItems = (items) => {
    localStorage.setItem(LOCATION_ITEMS_KEY, JSON.stringify(items));
};

export const getDeskItems = () => {
    const storedItems = localStorage.getItem(DESK_ITEMS_KEY);
    return storedItems ? JSON.parse(storedItems) : [];
};

export const setDeskItems = (items) => {
    localStorage.setItem(DESK_ITEMS_KEY, JSON.stringify(items));
};

export const getDeskSavedLocationItems = () => {
    const storedItems = localStorage.getItem(DESK_SAVED_LOCATION_ITEMS_KEY);
    return storedItems ? JSON.parse(storedItems) : [];
};

export const setDeskSavedLocationItems = (items) => {
    localStorage.setItem(DESK_SAVED_LOCATION_ITEMS_KEY, JSON.stringify(items));
};

export const cleanupSaturationStorage = () => {
    localStorage.removeItem(LOCATION_ITEMS_KEY);
    localStorage.removeItem(DESK_ITEMS_KEY);
    localStorage.removeItem(DESK_SAVED_LOCATION_ITEMS_KEY);
};
