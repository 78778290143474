import { createSelector } from '@reduxjs/toolkit';

export const selectDeskSlice = (state) => state.desks;

export const selectAllDesks = createSelector([selectDeskSlice], (desksSlice) => desksSlice.allDesks);

export const selectHasAvailableDesks = createSelector([selectAllDesks], (allDesks) => !!allDesks.length);

export const selectCurrentDesk = createSelector([selectDeskSlice], (desksSlice) => desksSlice.currentDesk);

export const selectFetchingDesksInProgress = createSelector([selectDeskSlice], (desksSlice) => desksSlice.fetchingDesksInProgress);

export const selectFetchingDesksError = createSelector([selectDeskSlice], (desksSlice) => desksSlice.fetchingDesksError);

export const selectMaxDeskTransactions = createSelector([selectDeskSlice], (deskSlice) => deskSlice.maxDeskTransactions);

export const selectIsActiveDeskConfirmed = createSelector([selectDeskSlice], (deskSlice) => deskSlice.isActiveDeskConfirmed);

export const selectExpectedFinishedTransactionMillis = createSelector([selectDeskSlice], (deskSlice) => deskSlice.expectedFinishedTransactionMillis);

export const selectDeskWarnings = createSelector([selectDeskSlice], (deskslice) => deskslice.deskWarnings);

export const selectIsDeskLoading = createSelector([selectDeskSlice], (deskslice) => deskslice.isDeskLoading);

export const selectPauseRequestSent = createSelector([selectDeskSlice], (desksSlice) => desksSlice.pauseRequestSent);

export const selectAllDeskLocations = createSelector([selectDeskSlice], (desksSlice) => desksSlice.allDeskLocations);

export const selectTableData = createSelector([selectDeskSlice], (desksSlice) => desksSlice.tableData);

export const selectSaturationPayload = createSelector([selectDeskSlice], (desksSlice) => desksSlice.saturationPayload);

export const selectDisableSaveButton = createSelector([selectDeskSlice], (desksSlice) => desksSlice.disableSaveButton);

export const selectSavedDesks = createSelector([selectDeskSlice], (desksSlice) => desksSlice.savedDesk);

export const selectSavingDeskFinished = createSelector([selectDeskSlice], (desksSlice) => desksSlice.savingDeskFinished);

export const selectNotFoundFilteredDeskMessage = createSelector([selectDeskSlice], (desksSlice) => desksSlice.notFoundFilteredDeskMessage);

export const selectIsFetchingSaturationInfo = createSelector([selectDeskSlice], (desksSlice) => desksSlice.isFetchingSaturationInfo);
