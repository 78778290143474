import { all, call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from './authSlice';
import { desksActions } from 'store/desksSlice/desksSlice';
import { sendLocalStorageSignal } from '../../utils/sessionStorageHandlers';
import axios from 'axios';
import { store } from 'store/store';
import { aiqLogger } from 'services/aiqLogger';
import { axiosAiq } from 'config';
import { notificationHandler } from 'utils/utils';
import { axiosSSO } from 'config';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { GENERAL } from 'config';

import { sharedWorkerActions, sharedWorker } from 'workers/sharedWorker/utils/utils';
const handleResponseError = (error) => {
    const resStatus = error.response?.status;
    const initiateLogout = resStatus === 401 || resStatus === 403;
    if (initiateLogout) {
        console.log(`handleResponseError(): Request failed with status=${resStatus}, url=${error?.config?.url}. Initiating logout...`);
        store.dispatch(authActions.logoutStart());
    }
    return Promise.reject(error);
};

axios.interceptors.response.use((response) => response, handleResponseError);
axiosAiq.interceptors.response.use((response) => response, handleResponseError);

function* getUserData() {
    try {
        const response = yield axiosAiq('/auth');
        const { username, roles, id, streamManagerToken } = response?.data || {};

        if (roles?.length) {
            const user = { username, roles, id };
            yield put(authActions.setUser(user));
            yield put(authActions.setStreamManagerToken(streamManagerToken));
        }
    } catch (err) {
        yield call(notificationHandler, {
            err,
            title: 'Error getting user data!',
        });
    }
    yield put(authActions.setIsUserDataRequested(true));
}

function* logout() {
    const { streamManagerToken = '' } = store.getState().auth ?? {};
    try {
        sharedWorkerActions.removeWindow({ id: sharedWorker.id });
        const webRtcEndpointIds = kurentoManager.getWebRTCEndpointIds();
        if (webRtcEndpointIds.length) {
            console.log(`logout(): Sending WebRTCEndpointId list for releasing ids=[${webRtcEndpointIds}]`);
            const sent = navigator.sendBeacon(
                `${GENERAL.ADDRESSES.HTTP.MAIN}/camera-stream/release-endpoints`,
                JSON.stringify({ webRtcEndpointIds, streamManagerToken })
            );
            console.log(`logout(): WebRTCEndpointIds ${sent ? '' : 'NOT '}sent!`);
        } else {
            console.log(`No WebRTCEndpointIds for release`, webRtcEndpointIds);
        }

        yield put(authActions.setUser(null));
        sessionStorage.clear();
        sendLocalStorageSignal('AIQ_UserLoggedOut');
        aiqLogger.resetLogger();
        yield put(desksActions.setCurrentDesk({}));
        yield put(desksActions.fetchDesksSuccess([]));
        yield axiosSSO.post('/logout');
    } catch (err) {
        yield call(notificationHandler, {
            err,
            title: 'Logout error.',
        });
    }
}

function* onGetUserData() {
    yield takeLatest(authActions.getUserData.type, getUserData);
}

function* onLogoutStart() {
    yield takeLatest(authActions.logoutStart.type, logout);
}

export function* authSaga() {
    yield all([call(onGetUserData), call(onLogoutStart)]);
}
