import { axiosAiq } from 'config';

export const fetchLocationsDetails = async () => {
    const response = await axiosAiq.get('/locations?details=true');
    const { data } = response;
    return data;
};

export const fetchSavedDesks = async () => {
    const response = await axiosAiq.get('/desks/gates');
    const { data } = response;
    return data;
};

export const saveDeskSaturation = async ({ payload }) => {
    const response = await axiosAiq.post('/desks/gates', payload);
    const { data } = response;
    return data;
};
