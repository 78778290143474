import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    relayActivitiesItems: [],
    filterParams: {
        location: '',
        date: '',
        searchedText: '',
    },
    locations: [],
    rowsPerPage: 50,
    pageIndex: 1,
    paginationCount: 1,
};

const relayActivitySlice = createSlice({
    name: 'relayActivity',
    initialState,
    reducers: {
        getRelayActivitiesStart() {},
        getRelayActivities(state, { payload }) {
            state.relayActivitiesItems = payload;
        },
        setfilteredParams(state, payload) {
            state.filterParams = { ...state.filterParams, ...payload.payload };
        },
        fetchAllLocations() {},
        setAllLocations(state, { payload }) {
            state.locations = payload;
        },
        setPageIndex(state, { payload }) {
            state.pageIndex = payload;
        },
        setPaginationCount(state, { payload }) {
            state.paginationCount = payload;
        },
    },
});

export const relayActivityActions = relayActivitySlice.actions;
export const relayActivityReducer = relayActivitySlice.reducer;
export const relayActivityName = relayActivitySlice.name;
