import { useEffect, useState } from 'react';
import styles from './Automation.module.css';
import TooltipIconButton from 'components/UI/Buttons/TooltipIconButton/TooltipIconButton';
import bugImage from 'assets/bug.png';
import { AUTOMATION_SOURCE, AUTOMATION_SOURCE_TOOLTIP, OVERWRITTEN_STYLE_NOT_ALLOWED_SOURCES } from 'utils/constants';
import { Tooltip } from '@mui/material';

function Automation({ disabled, automation, issues, onBugIconClick }) {
    const { source, info, sourceValue } = automation || {};
    const [initial, setInitial] = useState(true);
    const [automationOverwritten, setAutomationOverwritten] = useState(false);

    useEffect(() => {
        if (!source || !sourceValue) {
            return;
        }
        if (initial) {
            setInitial(false);
            return;
        }
        const allowOverwrittenStyles = !OVERWRITTEN_STYLE_NOT_ALLOWED_SOURCES.includes(source);
        setAutomationOverwritten(allowOverwrittenStyles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source, info, sourceValue]);

    const containerStyles = `${styles['automation-container']}${disabled ? ` ${styles['automation-container--disabled']}` : ''}`;

    const tooltip = AUTOMATION_SOURCE_TOOLTIP[source] || '';

    return (
        <div className={containerStyles}>
            <div className={styles['automation-container--source']}>
                {issues && (
                    <TooltipIconButton
                        clickHandler={onBugIconClick}
                        iconButtonConfig={{ tabIndex: '-1' }}
                        iconImage={bugImage}
                        title="Report Issue"
                    />
                )}
                <Tooltip title={tooltip} placement="top" disableHoverListener={!tooltip}>
                    <p className={`${automationOverwritten || source === AUTOMATION_SOURCE.AI_OCR ? ` ${styles['automation-overwritten']}` : ''}`}>
                        {source}
                    </p>
                </Tooltip>
                <p>{info}</p>
            </div>
            <div className={styles['automation-container--value']}>
                <p>{sourceValue}</p>
            </div>
        </div>
    );
}

export default Automation;
