//snackbarImports
import { SnackbarProvider } from 'notistack';
import { snackBarGeneralConfiguration } from 'config';
//redux imports
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setupStore } from 'store/store.js';
//
import { ThemeProvider } from '@emotion/react';
import theme from 'theme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

export default function wrapWithProviders(Component, options = {}) {
    const { environment, store = setupStore() } = options;
    return wrapWithRouterProvider(
        wrapWithStoreProvider(wrapWithLocalizationProvider(wrapWithThemeProvider(wrapWithSnackBarProvider(Component))), { store }),
        { environment }
    );
}

export const wrapWithThemeProvider = (Component) => <ThemeProvider theme={theme}>{Component}</ThemeProvider>;

export const wrapWithStoreProvider = (Component, { store = setupStore() }) => <Provider store={store}>{Component}</Provider>;

export const wrapWithRouterProvider = (Component, { environment }) => {
    const RouterProvider = environment === 'test' ? MemoryRouter : BrowserRouter;
    return <RouterProvider>{Component}</RouterProvider>;
};

export const wrapWithSnackBarProvider = (Component) => (
    <SnackbarProvider
        maxSnack={snackBarGeneralConfiguration.maxSnack}
        autoHideDuration={snackBarGeneralConfiguration.autoHideDuration}
        preventDuplicate={true}
    >
        {Component}
    </SnackbarProvider>
);

export const wrapWithLocalizationProvider = (Component) => <LocalizationProvider dateAdapter={AdapterDateFns}>{Component}</LocalizationProvider>;
