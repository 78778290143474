import classes from './Table.module.css';
import LinearLoader from '../Loaders/LinearLoader';
import React, { useCallback } from 'react';

import DataGrid, {
    Column,
    GroupPanel,
    Paging,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    Export,
    MasterDetail,
    Scrolling,
} from 'devextreme-react/data-grid';

function Table({
    data,
    columnsConfig,
    rowsPerPage = 10,
    styles,
    optionChangedHandler = () => {},
    keyExpr = 'id',
    disabled,
    focusedRowKey,
    selectedRowKeys,
    onRowDblClick = () => {},
    masterDetailComponent,
    onKeyDown = () => {},
    showNoDataMessage,
    loadingData,
    dataGridConfig,
}) {
    const onCellPrepared = useCallback((e) => {
        if (e.rowType === 'data') {
            e.row.cells.map((el) => {
                let bisonDate;
                let todayDate;
                switch (el.column.name) {
                    case 'activityDate':
                        bisonDate = new Date(el.data.activityDate).getDay();
                        todayDate = new Date().getDay();
                        if (bisonDate === todayDate) {
                            el.cellElement.style.backgroundColor = '#acccf8';
                        }
                        break;

                    default:
                        break;
                }

                return el;
            });
        }
    }, []);

    if (!data?.length || !columnsConfig) {
        if (loadingData) return <LinearLoader containerStyles={{ width: '80%', top: '3rem', margin: 'auto', position: 'relative' }} />;
        return showNoDataMessage ? <p className={classes['no-data']}>NO DATA</p> : <></>;
    }
    const columns = columnsConfig.map((segment) => {
        const { caption, columns } = segment;
        let columnsConfig;
        if (columns) {
            columnsConfig = columns.map((column) => {
                return <Column key={column.caption} {...column} />;
            });

            if (caption) {
                columnsConfig = (
                    <Column key={caption} caption={caption}>
                        {columnsConfig}
                    </Column>
                );
            }
        } else {
            columnsConfig = <Column key={segment.caption} {...segment} />;
        }

        return columnsConfig;
    });

    const focusedRow = focusedRowKey ? { focusedRowKey } : null;
    return (
        <div className={styles}>
            <DataGrid
                disabled={disabled}
                dataSource={data}
                keyExpr={keyExpr}
                allowColumnReordering={false}
                showBorders={true}
                focusedRowEnabled={true}
                allowColumnResizing={true}
                columnResizingMode="widget"
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                onOptionChanged={optionChangedHandler}
                onRowDblClick={onRowDblClick}
                onCellPrepared={onCellPrepared}
                onKeyDown={onKeyDown}
                loadPanel={{ enabled: true }}
                {...focusedRow}
                {...(selectedRowKeys && { selectedRowKeys })}
                {...dataGridConfig}
            >
                <GroupPanel visible={true} />
                <SearchPanel visible={true} />
                <Paging defaultPageSize={rowsPerPage - 1} mode="full" showNavigtionButtons={true} />
                <MasterDetail enabled={!!masterDetailComponent} component={masterDetailComponent} />
                <Scrolling useNative={true}></Scrolling>
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {/* <Editing mode="row" useIcons={true} allowUpdating={true} /> */}
                {columns}

                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        </div>
    );
}

export default React.memo(Table);
