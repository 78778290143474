export const vinCheckConfig = {
    transactionId: 'vinCheck', // will be attached as tag to WebRTCEndpoint
    liveStreamsTabId: 'vinCheck', //will be set as part of stream videoOutput id
    defaultStreamsPerPage: 2,
    stompBrokerTopic: `/topic/test-streams`,

    maxNumberOfSnapshots: 9,
    locationsEndpoint: '/locations/vin',
    getStreamsUrl: (locationId) => `/locations/${locationId}/cameras/vin?connect=true`,
};

export const tableConfig = {
    tableId: 'vinCheck',
    rowsPerPage: 15,
    columnsConfig: [
        { caption: 'Vin', dataField: 'vin', dataType: 'string', editable: 'false' },
        { caption: 'Confidence', dataField: 'confidence', dataType: 'string', editable: 'false' },
    ],
};
