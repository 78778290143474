import { writeInObj } from 'utils/utils';

export function autoPopulateTransactionLookupValue(form, transactionToSubmit) {
    const {
        form: { values },
        formConfig,
    } = form;
    const companyDataField = 'person.companyName';
    const filledTransactionToSubmit = structuredClone(transactionToSubmit);
    if (!filledTransactionToSubmit?.person?.companyId) {
        const companyFormFieldConfig = formConfig.fields.find((v) => v.dataField === companyDataField);
        const {
            lookup: { dataSource, displayExpr, referenceExpr },
            referenceField,
        } = companyFormFieldConfig || {};

        const automateCompanyId = dataSource?.find((company) => company?.[displayExpr] === values?.[companyDataField])?.[referenceExpr];
        writeInObj(filledTransactionToSubmit, referenceField, automateCompanyId);
    }
    return filledTransactionToSubmit;
}

export const populateInventoryDataAutomation = (transaction, form) => {
    const updatedTransaction = { ...transaction };
    const automationData = form?.form?.automation;
    const formConfig = form?.formConfig;

    Object.entries(automationData).forEach(([key, value]) => {
        const keys = key.split('.');
        const category = keys[0];
        const field = keys[1];
        if (!category || !field) return;
        // Only update if the property is null or undefined
        // We prioritize data input from the user than the automation
        if (!updatedTransaction[category]) {
            updatedTransaction[category] = {};
        }
        if (updatedTransaction[category][field]) return;

        const fieldConfig = formConfig?.fields?.find((field) => field.dataField === key);
        const lookupFieldConfig = fieldConfig?.lookup;
        if (!lookupFieldConfig) return;
        const { dataSource, displayExpr, valueExpr } = lookupFieldConfig;
        if (!dataSource) {
            updatedTransaction[category][field] = value?.sourceValue || '';
            return;
        }

        const lookupValue = dataSource.find((item) => item[displayExpr] === value.sourceValue);
        updatedTransaction[category][field] = lookupValue?.[valueExpr] || '';
    });

    return updatedTransaction;
};
