import { put } from 'redux-saga/effects';
import { snackbarActions } from '../store/snackbarsSlice/snackbarsSlice';
import { formControlActions } from 'store/formControlSlice/formControlSlice';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import { store } from 'store/store';
import { axiosAiq } from 'config';
import { kurentoConfig } from 'services/Kurento/KurentoConfig';
import { talkConfig } from 'services/Talk/TalkConfig';
import { kurentoManager } from 'services/Kurento/KurentoManager';
import { TRANSACTION_PAGE_TABS } from 'config';
import { selectActiveTab, selectActiveTransactionId, selectHasReopenedTransaction } from 'store/transactionsSlice/transactionsSelectors';
import { talkManager } from 'services/Talk/TalkManager';
import { CAMERA, PROPERTY_NOT_FOUND } from './constants';

const {
    DIVISION_TAB: { ID: divisionTabId },
    REOPENED_TRANSACTION_TAB: { ID: reopenedTransactionTabId },
} = TRANSACTION_PAGE_TABS;

export let keysPressed = {};

//////Event handlers on Transactions page/////////////////////////////////////////////////////////////////////////
export const fullScreenChangeHandler = () => !document.fullscreenElement && store.dispatch(transactionActions.setRequestFullScreenForTabId(0));

export const keyDownHandler = function (e) {
    keysPressed[e.key] = true;
    if (!Object.hasOwn(keysPressed, 'Control')) return;

    const state = store.getState();
    const transactionSlice = state.transactions;

    const activeTransactionId = selectActiveTransactionId(state);
    const activeTab = selectActiveTab(state);
    const { transactions: allTransactions } = transactionSlice;

    const key = e.key.toUpperCase();

    if (isFinite(key) || key === 'O') {
        e.preventDefault();
        const hasReopenedTransaction = selectHasReopenedTransaction(state);
        let tabId;
        if (isFinite(key)) {
            const index = hasReopenedTransaction ? +key : +key - 1;
            tabId = allTransactions[index]?.tabId;
            if (+key === 0) tabId = divisionTabId;
        }
        key === 'O' && hasReopenedTransaction && (tabId = reopenedTransactionTabId);

        if (tabId) store.dispatch(transactionActions.setActiveTab(tabId));
    }

    if (key === 'Z' && Object.hasOwn(keysPressed, 'Shift')) {
        e.preventDefault();
        store.dispatch(transactionActions.setRequestFullScreenForTabId(activeTab));
    }

    if (!activeTransactionId) return;

    const form = document.getElementById(activeTransactionId);

    if (key === 'ENTER' || key === 'DELETE') {
        e.preventDefault();
        if (e.target.tagName === 'INPUT') {
            e.target.inputBlurOnQuickSubmit = true;
            e.target.blur();
        }
        const transactionId = activeTransactionId;
        const locationId = form.dataset.locationId;
        const tabId = +form.dataset.tabId;

        key === 'ENTER' &&
            store.dispatch(
                formControlActions.submitFormStart({
                    transactionId,
                    locationId,
                    tabId,
                    autoPopulateEmptyFormFields: true,
                })
            );

        key === 'DELETE' &&
            store.dispatch(
                formControlActions.discardTransactionStart({
                    transactionId,
                    locationId,
                    tabId,
                })
            );
    }

    if (key === 'M') {
        e.preventDefault();
        const tabId = transactionSlice.activeTab;
        const tabsAudioObj = transactionSlice.tabsAudio;
        const muted = tabsAudioObj?.[tabId]?.isTabMuted;
        const soundOnStreamId = tabsAudioObj?.[tabId]?.soundOnStreamId;
        store.dispatch(transactionActions.setTabAudioMuted({ tabId, muted: !muted }));
        muted && soundOnStreamId && kurentoManager.enableAudio(soundOnStreamId);
    }

    if (key === 'D') {
        e.preventDefault();
        talkManager.toggleOutgoingAudioActive();
    }

    if (key !== 'Q' && key !== 'E') return;
    e.preventDefault();
    let element;
    switch (key) {
        case 'Q':
            element = form.querySelector(`input:not([disabled]):not([type='hidden'])`);
            break;
        case 'E':
            element = form.querySelector(`input[value='']:not([disabled]):not([type='hidden']`);
            break;
        default:
    }

    element.select();
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const keyUpHandler = (e) => {
    delete keysPressed[e.key];
};

export const clearKeyPressedObject = () => {
    keysPressed = {};
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Handling writing and reading from objects ////////////////////////////////////////////////////////////////////////
function createNameArray(namePath) {
    const nameArr = namePath.split(/[[\].]{1,2}/).reduce((acc, item) => {
        if (item === '') return acc;
        acc.push(item);
        return acc;
    }, []);
    const maxIndex = nameArr.length - 1;
    return [nameArr, maxIndex];
}

export const readFromObj = function (stateObj, namePath, del = false) {
    const [nameArr, maxIndex] = createNameArray(namePath);
    let property = stateObj;
    if (property)
        nameArr.forEach((item, i) => {
            if (property == undefined || property === PROPERTY_NOT_FOUND) return;
            if (item in property) {
                if (i === maxIndex && del) {
                    delete property[item];
                }
                property = property[item];
            } else {
                property = PROPERTY_NOT_FOUND;
            }
        });

    return property;
};

export const writeInObj = function (stateObj, namePath, value, options = {}) {
    if (!stateObj) return;
    const { action, index } = options;
    const [nameArr, maxIndex] = createNameArray(namePath);

    let property = stateObj;

    nameArr.forEach((item, i) => {
        if (i === maxIndex) {
            if (property === null) return;
            if (
                // (!value && value !== '' && !property[item]) ||
                property?.[item] === value ||
                !(item in property)
            )
                return;

            switch (action) {
                case undefined:
                    property[item] = value;

                    break;

                case 'push':
                    property[item].push(value);
                    break;

                case 'remove':
                    property[item].splice(index, 1);
                    break;

                default:
            }
            return;
        }

        if (item in property) {
            property = property[item];
        } else {
            // property[item] = namePath.includes(`[${arr[i + 1]}]`) ? [] : {};
            // property = property[item];
            return;
        }
    });
};

export function createFormConfigObject(obj, path = '') {
    let output = {};
    for (let key in obj) {
        let newPath = path === '' ? key : `${path}.${key}`;
        switch (true) {
            case typeof obj[key] !== 'object':
            case Array.isArray(obj[key]):
                output[newPath] = obj[key];
                newPath = path;
                break;

            default:
                output = {
                    ...output,
                    ...createFormConfigObject(obj[key], newPath),
                };
        }
    }
    return output;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function* notificationHandler({ err, action, title, variant = 'error' }) {
    if (action)
        yield put(
            action({
                title: title,
                message: err?.message || '',
                code: err?.code || '',
            })
        );

    if (err?.response?.status === 401) return;
    const errorDetails = err ? `|| CODE: ${err.code}  ||  MESSAGE: ${err.message}` : '';

    yield put(
        snackbarActions.enqueueSnackbar({
            message: `${title.toUpperCase()} ${errorDetails}`,
            options: {
                variant: variant,
            },
        })
    );
}

export function notificationHandlerSync({ err, action, title, variant = 'error' }) {
    if (action)
        store.dispatch(
            action({
                title: title,
                message: err?.message || '',
                code: err?.code || '',
            })
        );

    if (err?.response?.status === 401) return;
    const errorDetails = err ? `|| CODE: ${err.code}  ||  MESSAGE: ${err.message}` : '';

    store.dispatch(
        snackbarActions.enqueueSnackbar({
            message: `${title.toUpperCase()} ${errorDetails}`,
            options: {
                variant: variant,
            },
        })
    );
}

export async function getKurentoConfig() {
    try {
        const configurations = await axiosAiq('/admin/kurento/configuration');
        const { talkService, ...kurento } = configurations.data || {};
        kurentoConfig.configuration = kurento;
        talkConfig.configuration = talkService;
        return true;
    } catch (err) {
        err.response?.status !== 401 &&
            store.dispatch(
                snackbarActions.enqueueSnackbar({
                    message: `ERROR FETCHING KURENTO CONFIGURATION`,
                    options: {
                        variant: 'error',
                    },
                })
            );
    }
}

//////  LOG CUSTOM TEXT WITH CAMERA AND TRANSACTION INFO TO GRAFANA

export function logInfo(initialString, data) {
    const { camConfig, transactionInfo, camVideoId } = data;

    let string = `${initialString} ${logCameraInfo({
        ...camConfig,
        camVideoId,
    })}, ${transactionInfo?.uuid ? logTransactionInfo({ ...transactionInfo, filedName: data.fieldName }) : `environment="${transactionInfo.id} page"`} 
        `;

    console.log(string); //this will be sent to grafana
}

export const logTransactionInfo = (ti) =>
    `deskId=${ti.deskId}, transactionUuid="${ti.uuid}", transactionId=${ti.id} , locationId=${ti.locationId} , locationName="${
        ti.locationName
    }", gateId=${ti.gateId}, gateName="${ti.gateName}"${ti.filedName ? `, filedName="${ti.filedName}"` : ''}"`;

export const logCameraInfo = (cc) =>
    !cc.camId
        ? ''
        : `, cameraName="${cc?.name}", cameraType="${cc?.type}", cameraTab="${cc?.camVideoId}", url="${cc?.videoData?.url}"${cc?.talkUrl ? `, talkUrl="${cc?.talkUrl}"` : ''}", cameraId=${cc?.camId}`;

////// TRANSFORM CAMERA CONFIGURATION GOT FROM BE

/// this is pure function (it may be called inside reducer)
export function transformCameraConfiguration(camConfig, id) {
    const isActive = !camConfig.status || camConfig.status === CAMERA.STATUS_ACTIVE;
    const isHikvisionTWA = camConfig.talk_url && camConfig.audio_type === CAMERA.AUDIO_TYPE.HIKVISION_2WA && isActive;
    const is3CXTWA = camConfig.extension_number && camConfig.audio_type === CAMERA.AUDIO_TYPE.EXTENSION_3CX && isActive;
    return {
        ...(id && { id }),
        camId: camConfig.camera_id ? camConfig.camera_id : camConfig.id,
        gateCameraId: camConfig.id,
        talkUrl: isHikvisionTWA ? camConfig.talk_url : '',
        extensionNumber3CX: is3CXTWA ? camConfig.extension_number : '',
        ...(camConfig.name && { name: camConfig.name }),
        ...(camConfig.camera_type && { type: camConfig.camera_type }),
        ...(camConfig.gate_name && { gateName: camConfig.gate_name }),
        ...(camConfig.direction && { direction: camConfig.direction }),
        ...(camConfig.motion_detection_camera !== undefined && { motionDetectionCamera: camConfig.motion_detection_camera }),
        ...(camConfig.generated_name && { generatedName: camConfig.generated_name }),
        ...(camConfig.location_name && { locationName: camConfig.location_name }),
        ...(camConfig.level && { level: camConfig.level }),
        ...(camConfig.relays && { relays: camConfig.relays }),
        ...(camConfig.mtx_direct && { mtxDirect: camConfig.mtx_direct }),
        ...(camConfig.issues && { issues: camConfig.issues }),
        ...(camConfig.in_saturation && { inSaturation: camConfig.in_saturation }),
        ...(camConfig.status && { status: camConfig.status }),
        ...(camConfig.audio_type && { audioType: camConfig.audio_type }),
        videoData: {
            url: camConfig.url,
            playerEndpointId: camConfig['player_id'],
            ...(camConfig.stream_type && { streamType: camConfig.stream_type }),
        },
    };
}

export function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

export const hasDataInSection = (section, formState) => {
    const values = formState?.values;
    const automation = formState?.automation;
    // Check if section exists in values and contains a property with a value
    if (values && Object.keys(values).some((key) => key.startsWith(section) && values[key] !== null && values[key] !== '')) {
        return true;
    }
    // Check if section exists in automation and contains sourceValue
    return automation && automation[section] && automation[section].sourceValue;
};
