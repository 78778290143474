import Table from 'components/UI/table/Table';
import { tableConfig } from '../VinCheckConfig';

function TransactionsTable({ tableData }) {
    const { rowsPerPage, columnsConfig } = tableConfig;

    function optionChangedHandler(e) {
        if (e.name === 'paging') {
            if (!e.value) return;
            e.component.pageIndex(e.value);
        }
    }

    return (
        <Table data={tableData} columnsConfig={columnsConfig} keyExpr="id" rowsPerPage={rowsPerPage} optionChangedHandler={optionChangedHandler} />
    );
}

export default TransactionsTable;
