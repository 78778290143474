import { createSelector } from '@reduxjs/toolkit';

const slice = (state) => state.relayActivity;

export const relayActivitiesItems = createSelector([slice], (slice) => slice.relayActivitiesItems);
export const filterParams = createSelector([slice], (slice) => slice.filterParams);
export const locations = createSelector([slice], (slice) => slice.locations);
export const rowsPerPage = createSelector([slice], (slice) => slice.rowsPerPage);
export const pageIndex = createSelector([slice], (slice) => slice.pageIndex);
export const paginationCount = createSelector([slice], (slice) => slice.paginationCount);
