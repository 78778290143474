import { useEffect, useState } from 'react';
import styles from './VinCheck.module.css';
import LocationsDropdown from 'components/streamTest/LocationsDropdown';
import useStreams from 'hooks/useStreams/useStreams';
import VinCheckControler from 'components/vinCheck/VinCheckController';
import ProcessingSection from 'components/vinCheck/processingSection/ProcessingSection';
import { vinCheckConfig } from 'components/vinCheck/VinCheckConfig';
import CameraSections from 'components/vinCheck/cameraSections/CameraSections';
import { StreamManager } from 'hooks/useStreams/StreamsManager';
import TransactionsTable from 'components/vinCheck/transactionsTable/TransactionsTable';
import { base64ToBlob } from 'utils/base64ToBlob';

const {
    locationsEndpoint,
    maxNumberOfSnapshots,
    defaultStreamsPerPage,
    defaultStreamType = StreamManager.config.liveStreamType,
    liveStreamsTabId,
} = vinCheckConfig;

const initialState = StreamManager.getInitialState(liveStreamsTabId, defaultStreamType, defaultStreamsPerPage);

//TODO  disabling eslint warining for now, export function to separate file
// eslint-disable-next-line react-refresh/only-export-components
export const vinCheckPageStateUpdateFns = [];

function VinCheck() {
    // streams state
    const { state, streamManager, reduxDispatch, liveStreamCameras } = useStreams({ ...vinCheckConfig, initialState, defaultStreamType });

    const activeCameraId = liveStreamCameras?.[0]?.props?.camConfig?.camId; // with change of this we know that new location is "actiavated"
    const {
        fetchingStreams: { inProgress: fetchingStreamsInProgress, error: fetchingStreamsError },
    } = state;

    //vinCheck state
    const [vinCheckController, setVinCheckController] = useState(null);

    const [snapshotsState, setSnapshotsState] = useState(VinCheckControler.snapshotsInitialState);
    const [selectedSnapshots, setSelectedSnapshots] = useState();
    const [useMockValue, setUseMockValue] = useState();

    const [tableData, setTableData] = useState([]);

    const { snapshots, warnings, outputs } = snapshotsState;

    const [processingActions, setProcessingActions] = useState(VinCheckControler.processingActionsInitialState);
    const {
        requestInProgress: { sendingSnapshots, sendingConfirmation },
        isTransactionProcessed,
        transactionId,
        processedSnapshots,
        processedSnapshotsLoaded,
    } = processingActions;

    //effects
    useEffect(() => {
        if (!vinCheckController)
            setVinCheckController(new VinCheckControler(reduxDispatch, setSnapshotsState, setProcessingActions, maxNumberOfSnapshots));

        return () => setVinCheckController(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!processedSnapshotsLoaded) return;
        const element = document.getElementById('processing-container');
        element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [processedSnapshotsLoaded]);

    useEffect(() => {
        setProcessingActions && !vinCheckPageStateUpdateFns.includes(setProcessingActions) && vinCheckPageStateUpdateFns.push(setProcessingActions);
    }, [setProcessingActions]);

    const sendSnapshotsHandler = () => {
        const blob = base64ToBlob(selectedSnapshots?.source, 'image/png');

        const formData = new FormData();
        formData.append('image', blob);
        formData.append('useMock', useMockValue ? useMockValue : false);

        vinCheckController
            ?.sendSnapshotsHandler(formData)
            .then((result) => {
                result?.map((el) => {
                    el.id = el.vin;
                    return el;
                });
                setTableData(result);
            })
            .catch((result) => console.log(result));
    };

    const onClickHandler = (selectedSnapshotData) => {
        setSelectedSnapshots(selectedSnapshotData);
    };

    const emptySelectedSnapshot = () => {
        setSelectedSnapshots(null);
    };

    const getCheckdValue = (checkedValue) => {
        setUseMockValue(checkedValue);
    };
    return (
        <>
            <div className={styles['vin-check']}>
                <LocationsDropdown
                    onGetCameras={streamManager?.fetchLocationCameras}
                    onSelectedLocation={streamManager?.setSelectedLocation}
                    fetchingStreamsInProgress={fetchingStreamsInProgress}
                    fetchingStreamsError={fetchingStreamsError}
                    locationsUrl={locationsEndpoint}
                />
                <CameraSections
                    onTakeSnapshot={vinCheckController?.takeSnapshotHandler}
                    onRemoveSnapshot={vinCheckController?.removeSnapshotHandler}
                    snapshots={snapshots}
                    warnings={warnings}
                    sendingSnapshots={sendingSnapshots}
                    cameras={liveStreamCameras}
                    onClickHandler={onClickHandler}
                    emptySelectedSnapshot={emptySelectedSnapshot}
                    selectedSnapshots={selectedSnapshots}
                />
                <ProcessingSection
                    isTransactionProcessed={isTransactionProcessed}
                    hasActiveCamera={!!liveStreamCameras.length}
                    processedSnapshots={processedSnapshots}
                    sendingSnapshots={sendingSnapshots}
                    sendingConfirmation={sendingConfirmation}
                    snapshots={snapshots}
                    onSendSnapshots={sendSnapshotsHandler}
                    onSnapshotLoaded={vinCheckController?.processedSnaphotLoadedHanler}
                    onConfirmProcessedSnapshot={vinCheckController?.confirmDiscardProcessedSnapshotsHandler}
                    processedSnapshotsLoaded={processedSnapshotsLoaded}
                    vinTransactionId={transactionId}
                    selectedSnapshots={selectedSnapshots}
                    getCheckdValue={getCheckdValue}
                />

                <TransactionsTable tableData={tableData} activeCameraId={activeCameraId} />
            </div>

            <div style={{ height: '1600px', width: '200px' }}></div>
            {outputs.map(({ snapshot }) => snapshot)}
        </>
    );
}

export default VinCheck;
