import { call, all } from 'redux-saga/effects';

//sagas
import { desksSaga } from './desksSlice/desksSagas';
import { tableControlSaga } from './tableControlSlice/tableControlSagas';
import { transactionsSaga } from './transactionsSlice/transactionsSagas';
import { formControlSaga } from './formControlSlice/formControlSagas/index.js';
import { authSaga } from './authSlice/authSagas';
import { featureFlagSaga } from './featureFlag/featureFlagSaga';
import { relayActivitySaga } from 'features/relayActivity/store/relayActivitySagas';

export default function* rootSaga() {
    yield all([
        call(desksSaga),
        call(transactionsSaga),
        call(tableControlSaga),
        call(formControlSaga),
        call(authSaga),
        call(featureFlagSaga),
        call(relayActivitySaga),
    ]);
}
