import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    values: {},
};

const dynamicFormSlice = createSlice({
    name: 'dynamicForm',
    initialState,
    reducers: {
        setValue(state, { payload: { key, value } }) {
            state.values[key] = value;
        },
        clearValues(state) {
            state.values = {};
        },
    },
});

export const dynamicFormActions = dynamicFormSlice.actions;
export const dynamicFormReducer = dynamicFormSlice.reducer;
