import { FORMAT_TYPES, VALIDATION_TYPES } from './constants';

export function validateInputValue(value, validationRules) {
    let errorMessage = '';
    if (!validationRules) return errorMessage;
    for (const { type, pattern, message } of validationRules) {
        if (type !== VALIDATION_TYPES.PATTERN) continue;
        const regex = new RegExp(pattern);
        if (regex.test(value)) continue;
        errorMessage = message;
        break;
    }
    return errorMessage;
}

export function formatInputValue(value, format) {
    let formatedValue;
    switch (true) {
        case format === FORMAT_TYPES.UPPER_CASE && typeof value === 'string':
            formatedValue = value.toUpperCase();
            break;
        default:
            formatedValue = value;
    }
    return formatedValue;
}

export function cleanInputValue(value) {
    if (typeof value === 'string') return value.replace(/  +/g, ' ').trim();
    return value;
}
