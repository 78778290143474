import { kurentoManager } from 'services/Kurento/KurentoManager';
import { transformCameraConfiguration } from '../../utils/utils';
import { store } from 'store/store';
import { selectUser } from 'store/authSlice/authSelectors';
import { CAMERA } from 'utils/constants';

//

/// split it and optimize when got time

//

let user;
export function streamsReducer(state, { type, payload }) {
    const { tabsAudio, liveStreams, videosFailedToLoad, page } = state;
    const { liveStreamsTabId, sampleStreamsTabId } = this;
    if (!user) user = selectUser(store.getState())?.username;

    const { [sampleStreamsTabId]: sampleVideosTabAudio } = tabsAudio;

    switch (type) {
        case 'enableAudio': {
            const { camVideoId, tabId, muteTab } = payload;
            let newTabsAudio = { ...tabsAudio };
            for (let tab in state.tabsAudio) {
                if (tab === tabId) {
                    newTabsAudio = { ...tabsAudio, [tab]: { ...state.tabsAudio[tab] } };
                    if (newTabsAudio[tab].soundOnStreamId !== camVideoId && !muteTab) {
                        newTabsAudio[tab].isTabMuted = false;
                        newTabsAudio[tab].soundOnStreamId = camVideoId;
                    } else {
                        newTabsAudio[tab].isTabMuted = true;
                        newTabsAudio[tab].soundOnStreamId = '';
                    }
                } else {
                    newTabsAudio[tab] = { ...tabsAudio[tab], isTabMuted: true };
                }
            }
            return { ...state, tabsAudio: newTabsAudio };
        }

        case 'addVideoFailedToLoad':
            return {
                ...state,
                videosFailedToLoad: [...videosFailedToLoad, payload],
            };

        case 'removeVideoFailedToLoad': {
            const newVideosFailedToLoad = videosFailedToLoad.filter((videoId) => videoId !== payload);
            return { ...state, videosFailedToLoad: newVideosFailedToLoad };
        }

        case 'addLiveStreams': {
            const { data, pageNum, reset } = payload;

            if ((Object.hasOwn(liveStreams, pageNum) && !reset) || !data.length) return state;

            const liveStreamsToAdd = data.map((camConfig) => {
                const cameraConfig = transformCameraConfiguration(camConfig, `${camConfig.id}`);
                kurentoManager.create(user, cameraConfig);
                return cameraConfig;
            });

            const newLiveStreams = {
                ...liveStreams,
                [pageNum]: liveStreamsToAdd,
            };

            return {
                ...state,
                liveStreams: reset ? { [pageNum]: liveStreamsToAdd } : newLiveStreams,
            };
        }

        case 'setLiveStream': {
            const pageToSet = payload.pageNum;

            const availableAudioStreams = liveStreams[pageToSet].reduce((availableAudioStreams, config) => {
                if (config.audioType === CAMERA.AUDIO_TYPE.HIKVISION_2WA) {
                    availableAudioStreams.push(kurentoManager.getVideoElementId(liveStreamsTabId, config.id));
                }
                return availableAudioStreams;
            }, []);
            const hasAudio = !!availableAudioStreams.length;

            const liveStreamsAudio = {
                isTabMuted: !hasAudio,
                soundOnStreamId: '', //hasAudio ? availableAudioStreams[0] : '',
                availableAudioStreams,
            };

            const sampleVideosAudio = {
                ...sampleVideosTabAudio,
                isTabMuted: true,
            };

            return {
                ...state,
                tabsAudio: {
                    [sampleStreamsTabId]: sampleVideosAudio,
                    [liveStreamsTabId]: liveStreamsAudio,
                },
                page: { pageNum: pageToSet, lastPage: payload.lastPage },
                videosFailedToLoad: videosFailedToLoad.filter((videoId) => {
                    return videoId.includes(sampleStreamsTabId);
                }),
            };
        }

        case 'editStreamConfig': {
            const newCamConfig = payload;
            let modifiedLiveStreams;
            for (const page of Object.keys(liveStreams)) {
                const newPage = liveStreams[page].reduce((newPage, camera, i) => {
                    const edit =
                        camera.camId === newCamConfig.camId &&
                        (newCamConfig.status === 'INACTIVE' || camera.videoData.streamType === newCamConfig.videoData.streamType);
                    if (edit) {
                        const newPage = [...liveStreams[page]];
                        newPage[i] = { ...newPage[i], ...newCamConfig };
                        return { [page]: newPage };
                    }
                    return newPage;
                }, null);

                if (newPage) {
                    modifiedLiveStreams = { ...liveStreams, ...newPage };
                    break;
                }
            }

            if (modifiedLiveStreams) return { ...state, liveStreams: modifiedLiveStreams };

            const modifiedSampleStreams = state.sampleStreams.reduce((modifiedSampleStreams, stream, i) => {
                if (stream.camId === newCamConfig.camId) {
                    const newSampleStreams = [...state.sampleStreams];
                    newSampleStreams[i] = { ...newSampleStreams[i], ...newCamConfig };
                    return newSampleStreams;
                }
                return modifiedSampleStreams;
            }, null);

            if (modifiedSampleStreams) return { ...state, sampleStreams: modifiedSampleStreams };

            return state;
        }

        case 'resetLiveStreams':
            return {
                ...state,
                liveStreams: {},
                page: {
                    pageNum: 0,
                    lastPage: false,
                },
            };

        case 'setSampleStreams': {
            const sampleStreams = payload.map((sampleVideo) => {
                const config = {
                    id: sampleVideo.id,
                    camId: sampleVideo.id,
                    generatedName: sampleVideo.generated_name,

                    name: sampleVideo.name,
                    videoData: {
                        url: sampleVideo.url,
                        playerEndpointId: sampleVideo.player_id,
                        streamType: 'TEST',
                    },
                };
                kurentoManager.create(user, config);
                return config;
            });

            const sampleStreamsTabAudioConfig = {
                isTabMuted: true,
                soundOnStreamId: '',
                availableAudioStreams: payload.map((videoConfig) => {
                    return kurentoManager.getVideoElementId(sampleStreamsTabId, videoConfig.id);
                }),
            };

            return { ...state, sampleStreams, tabsAudio: { ...state.tabsAudio, [sampleStreamsTabId]: sampleStreamsTabAudioConfig } };
        }

        case 'setLastPage':
            return {
                ...state,
                page: { ...page, lastPage: true },
            };

        case 'setStreamsType':
            return {
                ...state,
                streamType: payload,
            };

        case 'setLiveStreamsPerPage':
            return { ...state, liveStreamsPerPage: payload };

        case 'setDispatchQueue':
            return { ...state, dispatchQueue: payload };

        case 'addToDispatchQueue':
            return { ...state, dispatchQueue: [...state.dispatchQueue, payload] };

        case 'setSelectedLocation':
            return {
                ...state,
                selectedLocation: payload,
            };

        case 'setFetchingStreamsError':
            return { ...state, fetchingStreams: { ...state.fetchingStreams, error: payload } };

        case 'setFetchingStreamsInProgress':
            return { ...state, fetchingStreams: { ...state.fetchingStreams, inProgress: payload } };

        case 'setFetchingSampleStreamsInProgress':
            return { ...state, fetchingSampleStreamsInProgress: payload };

        default:
            return state;
    }
}
