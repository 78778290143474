import { all, call, takeLatest, put, select, takeLeading, takeEvery } from 'redux-saga/effects';
import { desksActions } from './desksSlice';
import { notificationHandler } from 'utils/utils';
import { transactionActions } from 'store/transactionsSlice/transactionsSlice';
import { axiosAiq } from 'config';
import { selectAllDesks, selectIsActiveDeskConfirmed } from './desksSelectors';
import { selectUser } from 'store/authSlice/authSelectors';
import { selectActiveTab, selectIsReceivingTransactionsEnabled, selectIsVideoWallEnabled } from 'store/transactionsSlice/transactionsSelectors';
import { getSaturationCameras, getVideoWallCameras } from 'store/transactionsSlice/transactionsSagas';
import { stompTopics } from 'workers/sharedWorker/utils/utils';
import { fetchLocationsDetails, fetchSavedDesks, saveDeskSaturation } from 'services/saturationService';
import { cleanupSaturationStorage } from 'hooks/deskAssignLocation/saturationStorage';

export function* fetchDesks() {
    try {
        const response = yield axiosAiq.get('/admin/desk-configuration');
        const { maxDeskTransactions, expectedFinishedTransactionMillis } = response.data;
        yield put(desksActions.setMaxDeskTransactions(maxDeskTransactions));
        yield put(desksActions.setExpectedFinishedTransactionMillis(expectedFinishedTransactionMillis));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: desksActions.fetchDesksFailed,
            title: 'Error fetching desk configuration',
        });
    }

    try {
        const response = yield axiosAiq.get('/desks');
        const allDesks = response.data;
        yield put(desksActions.fetchDesksSuccess(allDesks));
        yield put(desksActions.checkDeskWarnings());
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: desksActions.fetchDesksFailed,
            title: 'Error fetching desks',
        });
    }
}

export function* fetchLocations() {
    try {
        const response = yield call(fetchLocationsDetails);
        yield put(desksActions.fetchLocationsSuccess(response));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: desksActions.fetchDesksFailed,
            title: 'Error fetching desk locations',
        });
    }
}

export function* fetchSavedDesk() {
    try {
        const response = yield call(fetchSavedDesks);
        yield put(desksActions.fetchSavedDesksSuccess(response));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: desksActions.fetchSavedDesksFailed,
            title: 'Error fetching saved desks informations',
        });
    }
}

export function* saveDeskAssignment(payload) {
    yield put(desksActions.setSavingDeskFinished(false));
    try {
        const response = yield call(saveDeskSaturation, payload);
        console.log('response saved saturation', response);
        yield call(notificationHandler, {
            variant: 'success',
            title: 'Successfully saved desk saturation',
        });
        cleanupSaturationStorage();
        yield put(desksActions.setSavingDeskFinished(true));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            action: desksActions.saveDeskInfoFailed,
            title: 'Error saving desk saturation',
        });
        yield put(desksActions.setSavingDeskFinished(false));
    }
}

export function* selectDesk({ payload: deskToSelect }) {
    const isActiveDeskConfirmed = yield select(selectIsActiveDeskConfirmed);
    if (!isActiveDeskConfirmed) return;

    const isTransactionPageActive = yield select(selectActiveTab);

    if (isTransactionPageActive) {
        yield put(transactionActions.unsubscribeFromStompBrokerTopic(stompTopics.transactionDesk));
        yield put(
            transactionActions.closeTransactionPanel({
                closeAllPanels: true,
                closeKurentoClient: false,
            })
        );
    }

    // yield put(desksActions.setCurrentDeskInactive());
    yield put(desksActions.setCurrentDesk({}));

    const { desk, takenBy } = yield call(isDeskAvailable, deskToSelect);

    if (desk) {
        yield put(desksActions.setIsDeskLoading(true));
        yield put(desksActions.setCurrentDesk(desk));

        const isReceivingTransactionsEnabled = yield select(selectIsReceivingTransactionsEnabled);
        yield put(desksActions.setIsActiveDeskConfirmed(!isReceivingTransactionsEnabled)); //false

        const isVideoWallEnabled = yield select(selectIsVideoWallEnabled);
        isVideoWallEnabled && (yield call(getVideoWallCameras, { payload: desk.id }));

        if (isReceivingTransactionsEnabled && isTransactionPageActive) {
            yield call(getSaturationCameras, desk.id);
            const { desk: deskStillAvailable } = yield call(isDeskAvailable, deskToSelect);
            if (deskStillAvailable) {
                yield put(transactionActions.subscribeToStompBrokerTopic({ topic: stompTopics.transactionDesk, url: `/topic/desks.${desk.id}` }));
            }
        }
        yield put(desksActions.setIsDeskLoading(false));
    } else {
        if (isTransactionPageActive && takenBy)
            yield call(notificationHandler, {
                title: `${deskToSelect.name.toUpperCase()} is taken${takenBy ? ` by ${takenBy}` : ''}, please select different desk.`,
            });
    }
}

export function* checkDeskWarnings() {
    try {
        const response = yield axiosAiq.get(`/desks/warnings`);

        const timeFormater = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric', minute: 'numeric', second: 'numeric' });
        yield put(desksActions.setDeskWarnings({ deskWarnings: response.data, lastChecked: timeFormater.format(new Date()) }));
    } catch (err) {
        yield call(notificationHandler, {
            err,
            title: 'Error getting desk warnings',
        });
    }
}

export function* isDeskAvailable(deskToSelect) {
    const { username: activeUser } = yield select(selectUser);
    const allDesks = yield select(selectAllDesks);

    let takenBy;
    const desk = allDesks.find((desk) => {
        const hasSessions = !!desk.sessions && !!desk.sessions.length;
        const deskUser = desk.sessions?.[0]?.account?.username;
        const isAssignedToMe = hasSessions && !!deskUser && deskUser === activeUser;
        const isDeskToSelect = desk.id === deskToSelect.id;

        if (isDeskToSelect && hasSessions && !isAssignedToMe) takenBy = deskUser;

        return isDeskToSelect && (desk.status === 'INACTIVE' || isAssignedToMe);
    });

    return { desk, takenBy };
}

export function* onDesksFetchStart() {
    yield takeLatest(desksActions.fetchDesksStart.type, fetchDesks);
}

export function* onLocationsFetchStart() {
    yield takeLatest(desksActions.fetchLocationsStart.type, fetchLocations);
}

export function* onSavedDeskFetchStart() {
    yield takeLatest(desksActions.fetchSavedDeskStart.type, fetchSavedDesk);
}

export function* onSelectDesk() {
    yield takeLeading(desksActions.selectDesk.type, selectDesk);
}

export function* onSaveSaturation() {
    yield takeLeading(desksActions.saveDeskInfo.type, saveDeskAssignment);
}

export function* onCheckDeskWarnings() {
    yield takeEvery(desksActions.checkDeskWarnings.type, checkDeskWarnings);
}

export function* desksSaga() {
    yield all([
        call(onDesksFetchStart),
        call(onSelectDesk),
        call(onCheckDeskWarnings),
        call(onLocationsFetchStart),
        call(onSavedDeskFetchStart),
        call(onSaveSaturation),
    ]);
}
